import { useState } from 'react'

import { useRouter } from 'next/navigation'

import { useWillsModalContext } from '@/contexts/WillsModalContext'
import {
  Form,
  Modal,
  NumberInput,
  Select,
  TextInput,
} from 'ethos-design-system'
import { cmsModuleAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { setAndGetUserUuid } from 'lib/FeaturesAndExperiments/setAndGetUserUUID'
import { cn } from 'lib/client-utils'

const WillsModal = () => {
  const { isModalOpen, setIsModalOpen } = useWillsModalContext()
  const [errorMessage, setErrorMessage] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { push } = useRouter()

  const handleFormSubmit = async (formData: {
    firstName: string
    lastName: string
    state: string
    phone: string
  }) => {
    const userId = setAndGetUserUuid()

    if (isSubmitting) return
    setErrorMessage('')

    setIsSubmitting(true)
    try {
      const data = {
        First_Name: formData.firstName,
        Last_Name: formData.lastName,
        State: formData.state,
        Phone: formData.phone,
        External_Identifier: userId,
        Lead_Source: 'Wills LP',
      }

      const res = await fetch('/api/sendSalesForceData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })

      if (res.status === 200) {
        // set session storage with phone number
        sessionStorage.setItem('wills-phone', formData.phone)
        cmsModuleAnalytics.submittedWillsModalForm(formData)
        push('/wills-hold')
      } else {
        setErrorMessage('Something went wrong. Please try again.')
      }
    } catch (error) {
      console.error('Error:', error)
      setErrorMessage('Internal Server Error. Please try again.')
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleClose = () => {
    setIsModalOpen(false)
  }

  return (
    <Modal isOpen={isModalOpen} onDismiss={handleClose}>
      <div className="w-full max-w-[450px] px-4">
        <div className="flex flex-col items-center justify-start overflow-visible rounded-xl bg-white p-4 pt-5 md:p-6 md:pb-4">
          <div className="mb-3 flex w-full items-center justify-between gap-x-3">
            <div>
              <p className="m-0 font-medium md:text-lg">
                Draft your will with an expert
              </p>
              <div className="pt-1 text-[14px] text-neutral-500">
                Our specialist will call you to guide you through the process
              </div>
            </div>
            <div onClick={handleClose}>
              <CloseIcon />
            </div>
          </div>

          <Form config={{ ...formConfig, onSubmit: handleFormSubmit }}>
            {/* @ts-ignore */}
            {({ field, getFormIsValid }) => {
              return (
                <>
                  <div className="mb-4 flex w-full flex-col gap-4 md:flex-row">
                    <div className="flex-1">{field('firstName')}</div>
                    <div className="flex-1">{field('lastName')}</div>
                  </div>
                  <div className="mb-4 flex w-full flex-col">
                    {field('state')}
                  </div>
                  <div className="mb-6 flex w-full flex-col">
                    {field('phone')}
                  </div>
                  {errorMessage && (
                    <p className="m-0 mb-4 mr-auto text-left text-base text-red-600">
                      {errorMessage}
                    </p>
                  )}
                  <button
                    type="submit"
                    className={cn(
                      'mb-3 w-full cursor-pointer rounded-lg bg-[#056257] py-4 font-medium text-white disabled:cursor-not-allowed disabled:bg-stone-700',
                      isSubmitting && 'cursor-default bg-[#3a665e]'
                    )}
                    disabled={!getFormIsValid() || isSubmitting}
                  >
                    Submit
                  </button>
                  <p className="m-0 text-xs leading-[18px] text-neutral-600">
                    By clicking “Submit”, I confirm that I have received, read,
                    and agree to{' '}
                    <span className="underline">Phone/SMS Consent</span>.
                  </p>
                </>
              )
            }}
          </Form>
        </div>
      </div>
    </Modal>
  )
}

export default WillsModal

export enum UsStatesFull {
  AL = 'Alabama',
  AK = 'Alaska',
  AZ = 'Arizona',
  AR = 'Arkansas',
  CA = 'California',
  CO = 'Colorado',
  CT = 'Connecticut',
  DE = 'Delaware',
  DC = 'District Of Columbia',
  FL = 'Florida',
  GA = 'Georgia',
  HI = 'Hawaii',
  ID = 'Idaho',
  IL = 'Illinois',
  IN = 'Indiana',
  IA = 'Iowa',
  KS = 'Kansas',
  KY = 'Kentucky',
  LA = 'Louisiana',
  ME = 'Maine',
  MD = 'Maryland',
  MA = 'Massachusetts',
  MI = 'Michigan',
  MN = 'Minnesota',
  MS = 'Mississippi',
  MO = 'Missouri',
  MT = 'Montana',
  NE = 'Nebraska',
  NV = 'Nevada',
  NH = 'New Hampshire',
  NJ = 'New Jersey',
  NM = 'New Mexico',
  NY = 'New York',
  NC = 'North Carolina',
  ND = 'North Dakota',
  OH = 'Ohio',
  OK = 'Oklahoma',
  OR = 'Oregon',
  PA = 'Pennsylvania',
  RI = 'Rhode Island',
  SC = 'South Carolina',
  SD = 'South Dakota',
  TN = 'Tennessee',
  TX = 'Texas',
  UT = 'Utah',
  VT = 'Vermont',
  VA = 'Virginia',
  WA = 'Washington',
  WV = 'West Virginia',
  WI = 'Wisconsin',
  WY = 'Wyoming',
}

export const birthStateOptions = Object.entries(UsStatesFull).map(
  ([value, label]) => ({
    value,
    label: `${label} (${value})`,
  })
)

const phoneNumberValidator = (phoneNumber: string): string => {
  return phoneNumber && phoneNumber.replace(/\D/g, '').length === 10
    ? ''
    : 'Please enter a valid phone number.'
}

const inputTextValidator = (text: string, message?: string): string => {
  if (typeof text !== 'string') {
    return message ? message : 'The value entered is invalid'
  }
  if (text.trim() === '') {
    return message ? message : 'Please provide a value'
  }
  return ''
}

export const selectValidator = (value: string): string => {
  if (value === '' || value === null || value === undefined) {
    return 'Please provide a value'
  }
  return ''
}

const phoneNumberMask = [
  '(',
  /[2-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

const formConfig = {
  formName: 'Wills Forms',
  formId: 'WillsForm',
  autocompleteOff: false,
  fields: {
    firstName: {
      tid: 'firstName',
      labelCopy: 'First name',
      component: (props: any) => (
        <TextInput
          {...props}
          validator={inputTextValidator}
          allCaps={false}
          capitalize={true}
          labelCopy="First name"
          labelWeight="medium"
        />
      ),
    },
    lastName: {
      tid: 'lastName',
      labelCopy: 'Last name',
      component: (props: any) => (
        <TextInput
          {...props}
          validator={inputTextValidator}
          allCaps={false}
          capitalize={true}
          labelCopy="Last name"
          labelWeight="medium"
        />
      ),
    },
    state: {
      tid: 'state',
      labelCopy: 'State',
      component: (props: any) => (
        <Select
          {...props}
          validator={selectValidator}
          allCaps={false}
          capitalize={true}
          placeholder="Select"
          options={birthStateOptions}
          isSearchable={true}
        />
      ),
    },
    phone: {
      tid: 'phone',
      labelCopy: 'Phone number',
      component: (props: any) => (
        <NumberInput
          {...props}
          mask={phoneNumberMask}
          allCaps={false}
          capitalize={true}
          validator={phoneNumberValidator}
          placeholder="(555) 555 - 5555"
        />
      ),
    },
  },
}

const CloseIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 6.91L17.59 5.5L12 11.09L6.41 5.5L5 6.91L10.59 12.5L5 18.09L6.41 19.5L12 13.91L17.59 19.5L19 18.09L13.41 12.5L19 6.91Z"
      fill="#525252"
    />
  </svg>
)
