module.exports = [
  '/estimate/',
  '/life/easy-life-insurance-fast/',
  '/life/easy-term-life/',
  '/life/final-expense-easy/',
  '/life/life-insurance-and-estate-planning/',
  '/life/online-term-life-insurance/',
  '/life/term-life-made-easy/',
  '/will-and-trust/easy/',
  '/will-and-trust/instant/',
]
