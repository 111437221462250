import React from 'react'

import { Button, CloudinaryImage, Spacer } from 'ethos-design-system'

import Markdown from '../../global/Markdown'
import PriorityImage from '../../global/PriorityImage'
import styles from '../SingleCTA.module.scss'
import { SingleCTAInterface } from '../interface'

const FinalExpenseDefault = ({
  moduleData,
  ctaClick,
}: {
  moduleData: SingleCTAInterface
  ctaClick: () => void
}) => {
  const {
    heading,
    subHeading,
    ctaLabel,
    arrowIcon,
    trustImageGroups,
    groupImage,
    imageAlt,
  } = moduleData

  const navbarPadding =
    moduleData?.navbarPadding === '64' ? (
      <div className="hidden w-full md:block">
        <Spacer.H64 />
      </div>
    ) : (
      <div className="w-full">
        <Spacer.H80 />
      </div>
    )

  const TrustImageGroups = () => {
    const trustImagesClassName = styles.trustImages

    return (
      <div className={trustImagesClassName}>
        {trustImageGroups?.map((trustImageGroup, index) => (
          <div
            key={`trustImage-${index}`}
            className={styles.trustImageContainer}
          >
            <CloudinaryImage
              publicId={trustImageGroup.groupImage}
              alt={trustImageGroup.imageAlt}
              className={styles.trustImage}
              crop={CloudinaryImage.CROP_METHODS.FIT}
              height={[142, 142, 142, 142]}
              width={[85, 85, 85, 85]}
            />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={styles.finalExpense}>
      <div className={styles.container}>
        {moduleData.navbarPadding !== '0' && <>{navbarPadding}</>}
        <div className={styles.mainContent}>
          <h1 className={styles.header}>
            <Markdown input={heading || ''}></Markdown>
          </h1>
          <div className={styles.subText}>{subHeading}</div>
          <div className={styles.cta}>
            <Button.Medium.DarkSalamander
              fullWidth
              arrowIcon={!!arrowIcon}
              onClick={() => ctaClick()}
            >
              {ctaLabel}
            </Button.Medium.DarkSalamander>
          </div>
          <TrustImageGroups />
        </div>
      </div>
      <div className={styles.bgContainer}>
        <div className={styles.bgImage}>
          {groupImage && imageAlt && (
            <PriorityImage
              publicId={groupImage}
              fetchpriority="high"
              alt={imageAlt}
              crop={CloudinaryImage.CROP_METHODS.CROP}
              height={[0, 384, 729, 972]}
              width={[0, 768, 1440, 1920]}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default FinalExpenseDefault
