import { useEffect } from 'react'

import { useRouter } from 'next/router'

import { ParamKey } from '@/constants'
import Cookies from 'js-cookie'

const getQueryParams = () => ({
  anonymousId: Cookies.get('ajs_anonymous_id'),
  userUuid: Cookies.get('USER_UUID'),
})

export const useLinkClickHandler = (enabled: Boolean): void => {
  const router = useRouter()

  useEffect(() => {
    if (!enabled) return
    if (window.location.hostname.endsWith('ethos.com')) return

    const handleLinkClick = (event: MouseEvent): void => {
      const target = (event.target as HTMLElement).closest(
        'a'
      ) as HTMLAnchorElement | null

      if (!target || !target.href) return

      const url = new URL(target.href)
      const isExternalProtocol =
        !url.protocol.startsWith('http') ||
        url.hostname !== window.location.hostname

      if (isExternalProtocol) return // Allow native behavior for non-http links

      event.preventDefault()

      const searchParams = new URLSearchParams(window.location.search)
      const { anonymousId, userUuid } = getQueryParams()

      router.push({
        pathname: url.pathname,
        query: {
          ...Object.fromEntries(url.searchParams),
          ...Object.fromEntries(searchParams),
          [ParamKey.AnonymousId]: anonymousId,
          [ParamKey.UserUuid]: userUuid,
        },
      })
    }

    document.addEventListener('click', handleLinkClick)

    return () => {
      document.removeEventListener('click', handleLinkClick)
    }
  }, [enabled, router])
}
