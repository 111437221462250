import React, { useEffect } from 'react'

import { useRouter } from 'next/router'

import Cookies from 'js-cookie'

import { MetaTags } from '@/components/MetaTags'

import { useQuestionTemplate } from '@/hooks/features/useQuestionTemplate'
import useSiteAgentsUrl from '@/hooks/useSiteAgentsUrl'
import useSiteAppUrl from '@/hooks/useSiteAppUrl'

import { getQueryParamsCalendly } from '../../lib/@getethos/analytics/getQueryParametersCalendly'
import { getQueryParamsEstatePlanning } from '../../lib/@getethos/analytics/getQueryParametersEstatePlanning'
import paidLandingPages from '../../lib/paidLandingPages'
import {
  CALENDLY_LINKS,
  EP_URL,
  ESTATE_PLANNING_URL,
  PRIVACY_SUBDOMAIN,
  TOMORROW_APP_URL,
} from '../constants'

// TODO give app-template pages their own area in the /admin/ GUI for easier filtering
//      and also update the titles for ease of use in GUI (not that they need editing often)
//      https://app.asana.com/0/1116481661798430/1167015657556182/f

// This is meant to redirect users from the CMS path /app to the main app.
// We need to preserve the environment as we do this, i.e. stage to stage.
// You may also redirect to specific route, e.g. /app/login => APP_ROOT/login,
// but currently you must first set up a file like `content/pages/app-login.md`.
const AppTemplate = ({ pageContent }: any) => {
  const router = useRouter()
  const path = pageContent.pathKey
  const url = `${path}/${pageContent.url}`
  const siteApp = useSiteAppUrl()
  const siteAgents = useSiteAgentsUrl()
  let siteRedirect = siteApp
  if (path === 'agents-portal' || url === 'root/agents-portal') {
    siteRedirect = siteAgents
  } else if (url === 'partners/calendly') {
    siteRedirect = CALENDLY_LINKS.NEW_AGENT
  } else if (url === 'partners/calendly-strategic') {
    siteRedirect = CALENDLY_LINKS.STRATEGIC
  } else if (path === 'estate-planning' || url === 'root/estate-planning') {
    siteRedirect = ESTATE_PLANNING_URL
  } else if (path === 'ep-app' || url === 'root/ep-app') {
    siteRedirect = EP_URL
  } else if (path === 'tomorrow-app' || url === 'root/tomorrow-app') {
    siteRedirect = TOMORROW_APP_URL
  } else if (url === 'root/privacy' || url === 'privacy') {
    siteRedirect = PRIVACY_SUBDOMAIN
  }

  const { isTreatment, isLoading } = useQuestionTemplate()

  // redirect to the app
  useEffect(() => {
    // get previous path
    const previousPath = Cookies.get('cms_referral_pathname') || ''

    const currentDomain = window.location.hostname
      .split('.')
      .slice(-2)
      .join('.')

    const isEthosDomain = currentDomain === 'ethos.com'

    // Check if previous path is a paid landing page (non-redirect)
    const isNonRedirectUrl =
      paidLandingPages.some((url) => previousPath.includes(url)) &&
      !isEthosDomain

    const appPath = calculateMainAppUrl(
      window.location.pathname,
      siteRedirect,
      isNonRedirectUrl
    )
    let queryParams = window.location.search // will be `''` if no params
    if (!queryParams && Object.values(CALENDLY_LINKS).includes(siteRedirect)) {
      queryParams = getQueryParamsCalendly()
    }
    if ([EP_URL, TOMORROW_APP_URL].includes(siteRedirect)) {
      queryParams = getQueryParamsEstatePlanning()
    }

    if (isTreatment && !isLoading) {
      router.replace(`${appPath}/flow/question-template/${queryParams}`)
    } else {
      router.replace(`${appPath}${queryParams}`)
    }
  }, [router, siteRedirect, isTreatment, isLoading])

  return (
    <MetaTags
      pageContent={{
        pageMetadata: {
          metaPageTitle: 'Redirecting...',
          metaPageDescription: 'Redirecting...',
          pageMetaRobots: 'noindex, nofollow',
          pageSocialImage: '',
        },
      }}
    />
  )
}
export default AppTemplate

export function calculateMainAppUrl(
  cmsPath: string,
  mainDomain: string,
  ignoreRewrite = false
) {
  if (!mainDomain) {
    throw new TypeError('mainDomain is undefined')
  }

  // Avoid URL rewrite for agents
  if (!mainDomain.startsWith('agents.') && !ignoreRewrite) {
    // Use the current (deployed) domain if we're not on localhost
    const currentDomain = window.location.hostname
      .split('.')
      .slice(-2)
      .join('.')

    if (!currentDomain.startsWith('localhost')) {
      mainDomain = mainDomain.replace('ethoslife.com', currentDomain)
    }
  }

  // Override the old "stage" subdomain for the new staging.ethos.com
  if (mainDomain.endsWith('stage.ethos.com') && !ignoreRewrite) {
    mainDomain = mainDomain.replace('stage', 'staging')
  }

  const mainPath = cmsPath
    .replace(/^(\/app)/, '')
    .replace(/^(\/agents-portal)/, '')
    .replace(/^(\/partners\/calendly-strategic)/, '')
    .replace(/^(\/partners\/calendly)/, '')
    .replace(/^(\/estate-planning)/, '')
    .replace(/^(\/ep-app)/, '')
    .replace(/^(\/tomorrow-app)/, '')
    .replace(/^(\/privacy)/, '')

  return `https://${mainDomain}${mainPath}`
}
