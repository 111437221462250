import {
  WILLS_META_AFFILIATE_LP,
  WILLS_V3_4,
} from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const WILLS_V3_4_CONTROL_FLOW = 'wills-v3-4-control'
export const WILLS_V3_4_T1_FLOW = 'wills-v3-4-t1'
export const WILLS_V3_4_T2_FLOW = 'wills-v3-4-t2'
export const WILLS_V3_4_T3_FLOW = 'wills-v3-4-t3'
export const WILLS_V3_4_T4_FLOW = 'wills-v3-4-t4'

const getWillsMetaAffiliateLpFlow = (variation: string) => {
  switch (variation) {
    case WILLS_META_AFFILIATE_LP.VARIATIONS.CONTROL:
      return { flow: WILLS_V3_4_CONTROL_FLOW, isTreatment: true }
    case WILLS_META_AFFILIATE_LP.VARIATIONS.TREATMENT_3:
      return { flow: WILLS_V3_4_T3_FLOW, isTreatment: true }
    case WILLS_META_AFFILIATE_LP.VARIATIONS.TREATMENT_4:
      return { flow: WILLS_V3_4_T4_FLOW, isTreatment: true }
    default:
      return { flow: undefined, isTreatment: false }
  }
}

export const useWillsFunnelFlow = () => {
  const { variation, isLoading } = useDecisionForFlag({
    name: WILLS_V3_4.EXPERIMENT_KEY,
    fallbackVariation: WILLS_V3_4.VARIATIONS.CONTROL,
  })

  const {
    variation: willsMetaAffiliateLpVariation,
    isLoading: willsMetaAffiliateLpLoading,
    isEnabled: isWillsMetaAffiliateLpEnabled,
  } = useDecisionForFlag({
    name: WILLS_META_AFFILIATE_LP.EXPERIMENT_KEY,
    fallbackVariation: WILLS_META_AFFILIATE_LP.VARIATIONS.CONTROL,
  })

  let flow
  let isTreatment = false

  if (isWillsMetaAffiliateLpEnabled) {
    const { flow, isTreatment } = getWillsMetaAffiliateLpFlow(
      willsMetaAffiliateLpVariation
    )

    return {
      isLoading: willsMetaAffiliateLpLoading,
      isTreatment,
      isLeadForm: false,
      flow,
    }
  }

  switch (variation) {
    case WILLS_V3_4.VARIATIONS.CONTROL:
      flow = WILLS_V3_4_CONTROL_FLOW
      isTreatment = true
      break
    case WILLS_V3_4.VARIATIONS.TREATMENT_1:
      flow = WILLS_V3_4_T1_FLOW
      isTreatment = true
      break
    case WILLS_V3_4.VARIATIONS.TREATMENT_2:
      flow = WILLS_V3_4_T2_FLOW
      isTreatment = true
      break
    case WILLS_V3_4.VARIATIONS.TREATMENT_3:
      flow = WILLS_V3_4_T3_FLOW
      isTreatment = true
      break
    case WILLS_V3_4.VARIATIONS.TREATMENT_4:
      flow = WILLS_V3_4_T4_FLOW
      isTreatment = true
      break
    default:
      flow = undefined
  }

  return {
    isLoading,
    isTreatment,
    isLeadForm: false,
    flow,
  }
}
