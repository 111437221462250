import { useGlobalSettings } from '@/hooks/content/useGlobalSettings'

import Logo from './Logo'
import styles from './PhoneNavbar.module.scss'

interface PhoneNavbarProps {
  settings: Record<string, any>
}

export const PhoneNavbar = (props: PhoneNavbarProps) => {
  const globalSettings = useGlobalSettings('global.md', props.settings)

  return (
    <>
      <div className={styles.container}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.phoneNumberContainer}>
          <div className={styles.copy}>
            {globalSettings.phoneOnlyNavbarCopy}&nbsp;
          </div>
          <a
            className={styles.phoneNumber}
            href={globalSettings.phoneOnlyNavbarNumberLink}
            target="_blank"
          >
            {globalSettings.phoneOnlyNavbarNumber}
          </a>
        </div>
      </div>
    </>
  )
}
